import type { FetchError } from 'ofetch'
import { defineStore } from 'pinia'
import { ERROR_KEYS } from '~/constants/errors'
import type { AddCartItemProps } from '~/types/application'
import type { Clientorder, ClientorderItem } from '~/types/demegro'

export const useCartStore = defineStore('cartStore', () => {
    const authStore = useAuthStore()
    const { handleError } = useErrorHandler()

    // State
    const clientorder = ref<Clientorder | null>(null)
    const items = ref<ClientorderItem[]>([])
    const authenticated = toRef(authStore, 'authenticated')

    // Computed
    const totalItems = computed(() => items.value.reduce((total, item) => total + item.amount, 0))
    const totalPrice = computed(() => items.value.reduce((total, item) => total + (item.price || 0) * item.amount, 0))
    const itemsCount = computed(() => items.value.length)
    const isEmpty = computed(() => items.value.length === 0)
    const totalAmount = computed((): number => {
        if (!items.value?.length)
            return 0
        return items.value.reduce((total, item) => total + (Number(item.amount) || 0), 0)
    })

    const mergeEditingItems = (response: Clientorder): Clientorder => {
        if (!items.value?.length)
            return response

        const editingItems = items.value.filter(item => item.editing)
        if (!editingItems.length)
            return response

        return {
            ...response,
            items: response.items.map((newItem) => {
                const editingItem = editingItems.find(item => item.id === newItem.id)
                return editingItem || newItem
            }),
        }
    }

    // Actions
    const setCart = (cart: Clientorder | null) => {
        clientorder.value = cart
        items.value = cart?.items || []
    }

    const fetchCart = async () => {
        if (!authenticated.value) {
            setCart(null)
            return
        }

        await useFetch<Clientorder, FetchError>('/api/carts', {
            key: 'cart',
            lazy: true,
            immediate: true,
            watch: [authenticated],
            onRequest() {
                if (!authenticated.value) {
                    setCart(null)
                    return false
                }
            },
            transform: (response) => {
                if (!response)
                    return {} as Clientorder
                const mergedCart = mergeEditingItems(response)
                setCart(mergedCart)
                return mergedCart
            },
            onResponseError({ response }) {
                if (response.status === 401) {
                    authStore.handleRefresh()
                }
            },
            onResponse({ response }) {
                if (response.ok)
                    setCart(response._data as Clientorder)
            },
        })
    }

    const addItem = async (cartItem: AddCartItemProps) => {
        const { error } = await useFetch<Clientorder, FetchError>('/api/carts', {
            method: 'POST',
            body: cartItem,
            onResponse({ response }) {
                if (response._data)
                    setCart(response._data as Clientorder)
                fetchCart()
            },
        })

        handleError(error.value, { key: ERROR_KEYS.ADD_FAILED })
    }

    const updateItemAmount = async (cartItem: AddCartItemProps) => {
        const { error } = await useFetch<{ success: boolean, error?: string }, FetchError>(
            `/api/carts/${cartItem.clientorder_id}`,
            {
                method: 'PUT',
                body: cartItem,
            },
        )

        handleError(error.value, { key: ERROR_KEYS.UPDATE_FAILED })
    }

    const removeItem = async (cartItem: AddCartItemProps) => {
        const { error } = await useFetch<Clientorder, FetchError>(`/api/carts/${cartItem.clientorder_id}`, {
            method: 'DELETE',
            onResponse({ response }) {
                if (response._data)
                    setCart(response._data as Clientorder)
                fetchCart()
            },
        })

        handleError(error.value, { key: ERROR_KEYS.REMOVE_FAILED })
    }

    // Watchers
    watch(authenticated, (isAuthenticated) => {
        isAuthenticated ? fetchCart() : setCart(null)
    }, { immediate: true })

    return {
        // State
        clientorder: computed(() => clientorder.value),
        items: computed(() => items.value),
        // Computed
        totalItems,
        totalPrice,
        itemsCount,
        isEmpty,
        totalAmount,
        // Actions
        fetchCart,
        addItem,
        updateItemAmount,
        removeItem,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
