import type { ErrorKey, ErrorMessage, Locale } from '~/constants/errors'
import { ERROR_KEYS, errorMessages } from '~/constants/errors'

export interface CustomError extends Error {
    color?: string
}

export function useError() {
    const locale = ref<Locale>('nl')

    function getErrorFallback(key: unknown): ErrorMessage {
        const error = errorMessages[key as ErrorKey]?.[locale.value]
        if (!error) {
            console.warn(`No error found for key: ${key} and locale: ${locale.value}`)
            return errorMessages[ERROR_KEYS.ERROR][locale.value]
        }
        return error
    }

    function getError(key: unknown): ErrorMessage {
        const error = errorMessages[key as ErrorKey]?.[locale.value]
        if (!error) {
            throw new Error('Error not found')
        }
        return error
    }

    function setLocale(newLocale: Locale) {
        locale.value = newLocale
    }

    return {
        getError,
        setLocale,
        getErrorFallback,
        locale: readonly(locale),
    }
}
