// WHEN YOU ADD CLASSES, MAKE SURE YOU SAFELIST THEM IN TAILWIND.CONFIG.JS

export const colors = {
    bg: {
        danger: 'bg-red-500',
        warning: 'bg-yellow-500',
        success: 'bg-lime-demegro',
        info: 'bg-blue-demegro',
    },
    text: {
        danger: 'text-red-500',
        warning: 'text-yellow-500',
        success: 'text-lime-demegro',
    },
} as const

export type Colors = typeof colors
