import type { FetchError } from 'ofetch'
import { ERROR_KEYS, type ErrorKey } from '~/constants/errors'
import { DemegroError } from '~/utils/errors'

interface ErrorOptions {
    key: ErrorKey
    status?: number
    showAlert?: (message: string, bgColor: string) => void
}

export function useErrorHandler() {
    function createError(error: unknown, options: ErrorOptions): DemegroError {
        if (error instanceof DemegroError)
            return error
        console.log('useErrorHandler: createError', { error, options })

        if ((error as FetchError)?.data?.message) {
            const fetchError = error as FetchError
            console.log('useErrorHandler: createError:', { fetchError, error })
            return createDemegroError(
                fetchError.data.message || options.key,
                fetchError.status || options.status,
            )
        }

        return createDemegroError(options.key, options.status)
    }

    function handleError(error: unknown, options: ErrorOptions): void {
        console.log('handleError', { error, options })
        if (!error)
            return

        const demegroError = createError(error, options)
        console.log('demegroError', { demegroError, options })
        if (options.showAlert) {
            console.log('showAlert', demegroError.message, demegroError.color)
            options.showAlert(demegroError.message, demegroError.color)
            return
        }

        throw demegroError
    }

    return {
        handleError,
    }
}
