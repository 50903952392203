import type { ErrorKey, Locale } from '~/constants/errors'

export class DemegroError extends Error {
    constructor(
        public key: ErrorKey,
        public message: string,
        public color: string,
        public statusCode?: number,
    ) {
        super(message)
        this.name = 'DemegroError'
    }

    static isKnownError(error: unknown): error is DemegroError {
        return error instanceof DemegroError
    }
}

// Factory function to create errors
export function createDemegroError(
    errorKey: ErrorKey | unknown,
    statusCode?: number,
    locale: Locale = 'nl',
): DemegroError {
    const { getError, getErrorFallback, setLocale } = useError()

    setLocale(locale)
    // Normalize the error key
    const key = typeof errorKey === 'string'
        ? errorKey
        : errorKey instanceof Error
            ? errorKey.message
            : String(errorKey)

    try {
        const errorInfo = getError(key)
        return new DemegroError(
            key as ErrorKey,
            errorInfo.message,
            errorInfo.color,
            statusCode,
        )
    }
    catch {
        // Fall back to default error using existing fallback logic
        const fallback = getErrorFallback(key)
        return new DemegroError(
            'error' as ErrorKey,
            fallback.message,
            fallback.color,
            statusCode,
        )
    }
}
