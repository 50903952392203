export function useLoginButton() {
    const isPinging = useState('loginButtonPinging', () => false)

    const pingLoginButton = () => {
        isPinging.value = true

        setTimeout(() => {
            isPinging.value = false
        }, 2000)
    }

    return {
        isPinging,
        pingLoginButton,
    }
}
