import { colors } from './colors'

export const ERROR_KEYS = {
    ERROR: 'error',
    NO_STOCK: 'no_stock_for_expiration',
    ADD_FAILED: 'add_failed',
    UPDATE_FAILED: 'update_failed',
    REMOVE_FAILED: 'remove_failed',
    INVALID_AMOUNT: 'invalid_amount',
    LOAD_FAILED: 'load_failed',
    INVALID_CREDENTIALS: 'invalid_credentials',
    SESSION_EXPIRED: 'session_expired',
    LOGIN_REQUIRED: 'login_required',
    SCANGO_LOAD_FAILED: 'scango_load_failed',
    MINMAX_INVALID: 'minmax_invalid',
    MISSING_PARAMETERS: 'missing_parameters',
} as const

// Type for error keys
export type ErrorKey = typeof ERROR_KEYS[keyof typeof ERROR_KEYS]

// Supported locales
export type Locale = 'nl' | 'en'

// Error message structure
export interface ErrorMessage {
    message: string
    color: string
}

export type ErrorMessagesMap = {
    [K in ErrorKey]: Record<Locale, ErrorMessage>
}

// Error messages implementation
export const errorMessages: ErrorMessagesMap = {
    // General
    error: {
        nl: { message: 'Er is iets fout gegaan', color: colors.bg.danger },
        en: { message: 'An error occurred', color: colors.bg.danger },
    },
    missing_parameters: {
        nl: { message: 'Er ontbreken parameters', color: colors.bg.danger },
        en: { message: 'Missing parameters', color: colors.bg.danger },
    },

    // Cart
    no_stock_for_expiration: {
        nl: { message: 'Geen voorraad beschikbaar voor deze vervaldatum', color: colors.bg.warning },
        en: { message: 'No stock available for this expiration date', color: colors.bg.warning },
    },
    add_failed: {
        nl: { message: 'Er is iets fout gegaan bij het toevoegen aan uw winkelwagen', color: colors.bg.danger },
        en: { message: 'Failed to add item to cart', color: colors.bg.danger },
    },
    update_failed: {
        nl: { message: 'Er is iets fout gegaan bij het bijwerken van het aantal', color: colors.bg.danger },
        en: { message: 'Failed to update item quantity', color: colors.bg.danger },
    },
    remove_failed: {
        nl: { message: 'Er is iets fout gegaan bij het verwijderen van het artikel', color: colors.bg.danger },
        en: { message: 'Failed to remove item from cart', color: colors.bg.danger },
    },
    invalid_amount: {
        nl: { message: 'Je moet minimaal 1 aantal toevoegen aan je winkelwagen', color: colors.bg.warning },
        en: { message: 'You must add at least 1 item to your cart', color: colors.bg.warning },
    },
    load_failed: {
        nl: { message: 'Er is iets fout gegaan bij het laden van je winkelwagen', color: colors.bg.warning },
        en: { message: 'Failed to load your cart', color: colors.bg.warning },
    },

    // Auth
    invalid_credentials: {
        nl: { message: 'Ongeldige inloggegevens', color: colors.bg.danger },
        en: { message: 'Invalid credentials', color: colors.bg.danger },
    },
    session_expired: {
        nl: { message: 'Uw sessie is verlopen, log opnieuw in', color: colors.bg.warning },
        en: { message: 'Your session has expired, please log in again', color: colors.bg.warning },
    },
    login_required: {
        nl: { message: 'Je moet eerst inloggen om je winkelwagen te beheren', color: colors.bg.info },
        en: { message: 'You must be logged in to manage your cart', color: colors.bg.info },
    },

    // Scan & Go
    scango_load_failed: {
        nl: { message: 'Er is een fout opgetreden bij het laden van Scan & Go', color: colors.bg.danger },
        en: { message: 'An error occurred while loading Scan & Go', color: colors.bg.danger },
    },
    minmax_invalid: {
        nl: { message: 'Minimum waarde kan niet groter zijn dan maximum waarde', color: colors.bg.warning },
        en: { message: 'Minimum value cannot be greater than maximum value', color: colors.bg.warning },
    },
}
